exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-biologica-diagnostics-tsx": () => import("./../../../src/pages/biologica-diagnostics.tsx" /* webpackChunkName: "component---src-pages-biologica-diagnostics-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-food-companion-tsx": () => import("./../../../src/pages/food-companion.tsx" /* webpackChunkName: "component---src-pages-food-companion-tsx" */),
  "component---src-pages-gportal-tsx": () => import("./../../../src/pages/gportal.tsx" /* webpackChunkName: "component---src-pages-gportal-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-oneplanet-tsx": () => import("./../../../src/pages/oneplanet.tsx" /* webpackChunkName: "component---src-pages-oneplanet-tsx" */),
  "component---src-pages-pantum-detect-tsx": () => import("./../../../src/pages/pantum-detect.tsx" /* webpackChunkName: "component---src-pages-pantum-detect-tsx" */),
  "component---src-pages-pmg-tsx": () => import("./../../../src/pages/pmg.tsx" /* webpackChunkName: "component---src-pages-pmg-tsx" */),
  "component---src-pages-printilo-tsx": () => import("./../../../src/pages/printilo.tsx" /* webpackChunkName: "component---src-pages-printilo-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-samedica-crm-tsx": () => import("./../../../src/pages/samedica-crm.tsx" /* webpackChunkName: "component---src-pages-samedica-crm-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */)
}

