module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#f1f1f1","theme_color":"#f1f1f1","display":"minimal-ui","icon":"src/images/icons/fav.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6a888a029328385fb66a528060f5e13b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-SJY8PJXN30","cookieName":"ga-cookie-settings","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"YOUR_GOOGLE_TAG_MANAGER_TRACKING_ID","cookieName":"gtm-cookie-settings","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"YOUR_FACEBOOK_PIXEL_ID","cookieName":"fb-pixel-cookie-settings"},"tikTokPixel":{"pixelId":"YOUR_TIKTOK_PIXEL_ID","cookieName":"tiktok-pixel-cookie-settings"},"hotjar":{"hjid":"YOUR_HOTJAR_ID","hjsv":"YOUR_HOTJAR_SNIPPET_VERSION","cookieName":"hotjar-cookie-settings"},"linkedin":{"trackingId":"YOUR_LINKEDIN_TRACKING_ID","cookieName":"linked-in-cookie-settings"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
